import React, { createContext, useState, useContext, useEffect } from "react";

const DrawerContext = createContext();

export const useDrawer = () => useContext(DrawerContext);

export const DrawerProvider = ({ children }) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => setDrawerOpen((prev) => !prev);

  const [message, setMessage] = useState("");

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if (query.has("success")) {
      setMessage("Commande passée ! Vous recevrez une confirmation par email.");
    }

    if (query.has("canceled")) {
      setMessage("La commande a été annulée. Continuez vos achats et passez à la caisse quand vous êtes prêt.");
    }
  }, []);

  return (
    <DrawerContext.Provider value={{ isDrawerOpen, toggleDrawer, message, setMessage }}>
      {children}
    </DrawerContext.Provider>
  );
};