import * as React from 'react';

import '@fontsource/roboto/300.css';
import './about.scss';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery'

import Grid from '@mui/material/Unstable_Grid2';
import {Typography, Tooltip, Box} from '@mui/material';

import RamassageImg from './ramassage.png'; 
import LavageImg from './lavage.png'; 
import BroyageImg from './broyage.png'; 
import PressageImg from './pressage.png';


function Titre() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  <Grid xs={12}>
    <Typography
      variant="h5"
      sx={{
        m: '0.5rem',
        fontWeight: 'bold',
        textAlign: 'center', // Centrer horizontalement le texte
      }}
    >
      LES ÉTAPES DE PRODUCTION DU JUS DE POMME BRIARD
    </Typography>
  </Grid>
}

function Ramassage() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid xs={isSmallScreen ? 12 : 6} textAlign="justify"  >
      <Box  sx={{bgcolor: 'white', p: 2, borderRadius: 5, overflow: 'hidden'}}>
        <Typography variant="h6" sx={{ m: '0.5rem', fontWeight: 'bold', textAlign: 'center'}}>
          LE RAMASSAGE <br />
        </Typography>
        <Typography variant="h6" sx={{ m: '0.5rem', textAlign: 'center'}}>
          Une tradition ancestrale  
        </Typography>

        <Typography variant="body1"  >
        {' '}
        <Box
          component="img"
          src={RamassageImg}
          alt="ramassage des pommes"
          sx={{
            float: 'left', // Permet d'aligner l'image à gauche
            marginRight: '16px', // Espace entre l'image et le texte
            marginBottom: '8px', // Espace sous l'image
            maxWidth: `40%`, // Ajuste dynamiquement la largeur en fonction du zoom
            transition: 'max-width 0.3s ease', // Animation fluide
            height: 'auto', // Conserve les proportions
          }}
        />

          Ici, sur la vallée de l'Aubetin, on appelle cette récolte <strong>la deuxième moisson de l'année. </strong> <br /> <br />
          
          Les arbres les plus chargés en fruit sont 

          {' '}  
          <Tooltip title="Une grande perche est utilisée pour décrocher les fruits en hauteur">
            <i>gaulés</i>
          </Tooltip> pour soulager les branches et les pommes sont ensuite ramassées à même le sol. 

          C'est à ce moment qu'à lieu <strong>un premier tri</strong>. 
          Les fruits les plus âbimés ou croqués par des animaux sauvages sont écartés. 
          Le reste est placé en {' '}      
          
          <Tooltip title="Les caisses empilables sont essentielles pour la manipulation des fruits">
            <i>caisse gerbables</i>
          </Tooltip>. <br /><br />

          La fraicheur de l'automne et l'herbe haute aidant, certaines variétés comme la Belle Joséphine peuvent rester à terre plusieurs semaines sans se gâter !
        </Typography>  <br />

        <Typography variant="body1" >
          A l'inverse les récalcitrantes Starking restent accrochés aux bras de leurs géniteurs comme de véritables koalas. Sans notre aide, elles ne mettraient jamais pied à terre et finiraient par pourrir sur l'arbre !
        </Typography>   
      </Box>             
    </Grid>
  );
}

function Lavage() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid xs={isSmallScreen ? 12 : 6} textAlign="justify"  >
      <Box sx={{bgcolor: 'white', p: 2, borderRadius: 5, overflow: 'hidden'}}>
        <Typography variant="h6" sx={{ m: '0.5rem', fontWeight: 'bold', textAlign: 'center'}}>
          LE LAVAGE
        </Typography>
        <Typography variant="h6" sx={{ m: '0.5rem', textAlign: 'center'}}>
          Le secret pour un jus réussi 
        </Typography>

        <Typography variant="body1"  >
        {' '}
        <Box
          component="img"
          src={LavageImg}
          alt="lavage des pommes"
          sx={{
            float: 'left', // Permet d'aligner l'image à gauche
            marginRight: '16px', // Espace entre l'image et le texte
            marginBottom: '8px', // Espace sous l'image
            maxWidth: `40%`, // Ajuste dynamiquement la largeur en fonction du zoom
            transition: 'max-width 0.3s ease', // Animation fluide
            height: 'auto', // Conserve les proportions
          }}
        />

          Il se passe parfois plusieurs jours entre la récolte et le lavage. Durant ce <strong> temps de maturation </strong> la pomme va continuer à mûrir et développer ses arômes. <br /><br />
          Dans la mesure où le produit est consommé directement au pied du pressoir il est impératif que les fruits soient débarrassés de toutes impuretés. <br /><br />
          Pour cela on commence par vider les caisses dans une grande piscine d'eau, un peu comme {' '}     
        <Tooltip title="Jeu qui consiste à essayer d'attraper une pomme immergée dans de l'eau avec la bouche et les mains dans le dos. Certains disent que cette pratique provient d'un rite pratiqué autrefois par les druides scandinaves.">
          <i>le jeu de la pomme</i>
        </Tooltip> à Halloween. L'eau jouant le rôle de solvant naturel, ce premier détrempage a pour objet de dissoudre la terre et de faire ressortir de leurs habitations les éventuels occupants vermiformes.
          Les pommes sont lavées autant de fois que nécessaire puis rincées à l'eau claire et mises à égouter en attendant d'être rangées.  <strong>Un second tri </strong> a lieu à ce moment-ci. Les pommes les moins âbimées sont mises de côtés pour faire de la compote.
        </Typography>
      </Box>   
    </Grid>
  )
}

function Broyage() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return(
    <Grid xs={isSmallScreen ? 12 : 6} textAlign="justify"  >
      <Box sx={{bgcolor: 'white', p: 2, borderRadius: 5, overflow: 'hidden'}}>
      <Typography variant="h6" sx={{ m: '0.5rem', fontWeight: 'bold', textAlign: 'center'}}>
        LE BROYAGE
      </Typography>
      <Typography variant="h6" sx={{ m: '0.5rem', textAlign: 'center'}}>
        Le grugeoir, pièce maitresse du jus de pomme frais
      </Typography>

      <Typography variant="body1"  >

        {' '}
        <Box
          component="img"
          src={BroyageImg}
          alt="broyage des pommes"
          sx={{
            float: 'left', // Permet d'aligner l'image à gauche
            marginRight: '16px', // Espace entre l'image et le texte
            marginBottom: '8px', // Espace sous l'image
            maxWidth: `40%`, // Ajuste dynamiquement la largeur en fonction du zoom
            transition: 'max-width 0.3s ease', // Animation fluide
            height: 'auto', // Conserve les proportions
          }}
        />

        Le broyage se faisait autrefois au moyen d'un {' '}      
        <Tooltip title="Un broyeur en forme d'entonnoir utilisé pour produire du jus de pomme et du cidre.">
          <i>grugeoir</i>
        </Tooltip> en bois et surtout ... manuel ! Ce type d'outil, très dur à actionner, permet de faire éclater la pomme puis de l'écraser pour en extraire le maximum de jus lors du pressage. Il fallait quelque fois couper préalablement les pommes avec un couteau pour se faciliter le travail. Ce processus long et energivore a ensuite été amélioré en munissant l'appareil d'une courroie et d'un moteur. <br /><br />

        Contrairement au cidre (où l'alcool joue le rôle de purificateur) et au jus pasteurisé (où la chauffe à 65                   degrés tue les pathogènes), le jus de pomme frais doit faire l'objet d'une hygiène rigoureuse. Par exemple la durée où la pomme broyée est en contact avec l'air ambiant ne doit pas exéder 20 minutes. Ainsi nous faisons le choix d'utiliser un broyeur électrique en inox qui nous garanti un travail rapide et in fine <strong> un jus sain et immédiatement consommable</strong>.
      </Typography>
      </Box>   
    </Grid>
  )
}

function Pressurage() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return(
    <Grid xs={isSmallScreen ? 12 : 6} textAlign="justify"  >
      <Box sx={{bgcolor: 'white', p: 2, borderRadius: 5, overflow: 'hidden'}}>
      <Typography variant="h6" sx={{ m: '0.5rem', fontWeight: 'bold', textAlign: 'center'}}>
        LE PRESSURAGE
      </Typography>

      <Typography variant="h6" sx={{ m: '0.5rem', textAlign: 'center'}}>
        La réunion des arômes ... et des amis !
      </Typography>

      <Typography variant="body1"  >
        {' '}
        <Box
          component="img"
          src={PressageImg}
          alt="pressage des pommes"
          sx={{
            float: 'left', // Permet d'aligner l'image à gauche
            marginRight: '16px', // Espace entre l'image et le texte
            marginBottom: '8px', // Espace sous l'image
            maxWidth: `40%`, // Ajuste dynamiquement la largeur en fonction du zoom
            transition: 'max-width 0.3s ease', // Animation fluide
            height: 'auto', // Conserve les proportions
          }}
        />

        Le pressage c'est l'aboutissement d'un travail de longue haleine mais c'est aussi un moment de détente et de convivialité. On échange sur notre savoir-faire tout en sirotant, un verre de jus à la main. {' '}      
        <Tooltip title="Le processus d'oxydation sera plus ou moins prononcé en fonction de la température exterieur. Ainsi une pressée par temps de gel donnera un jus translucide tandis que, les jours plus doux, il sera couleur châtaigne.">
          <i>La météo capricieuse</i>
        </Tooltip> n'affecte en rien les enfants qui rythme joyeusement le pressage à mesure que les forces viennent à manquer. Lorsque les rires s'estompent et que le manège peine à trouver des participants, c'est le moment de {' '}      
        <Tooltip title="La pression tout comme la propagation du son dans l'air se déplace de proche en proche. Les cidriculteurs laisse parfois le pressoir sous pression durant 3 jours entiers ! Lorsque la matière du bas est soumise à la même pression que la partie haute, il est temps de s'arrêter.">
          <i>laisser la pression circuler</i>
        </Tooltip> <br /><br />
        Pour cette étape un pressoir en bois de hêtre est utilisé afin de venir enrichir le panel de {' '}      
        <Tooltip title="De petits champignons unicellulaires qui se trouvent sur la surface des pommes.">
          <i>levures sauvages</i>
        </Tooltip> déjà présentes dans le jus. Ces levures participent grandement aux saveurs qui se développeront par la suite lors de la fermentation. Le cidre obtenu sera ainsi différent d'une année sur l'autre en fonction des conditions de croissance des pommes et de l'environnement de transformation, on appelle cela un <i> millésime </i>. 
      </Typography>
      </Box>   
    </Grid>
  )
}

export default function About(){
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div id="about" className="About">
      <Titre />

      <Grid xs={12} container spacing={2} padding={2} direction={isSmallScreen ? "column" : "row"}>
        <Ramassage />
        <Lavage />
        <Broyage />
        <Pressurage />
      </Grid>
    </div>
  );
}