
export async function getStore() {
  let response = await fetch(`${process.env.REACT_APP_NGROK_URL}/store/get`, {
    method: 'POST',      
    headers: {
        "Content-Type": "application/json",
      },
  });

  const articles = await response.json();
  return articles;
}