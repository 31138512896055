import { getStore} from "../store/service";

import getBrowserFingerprint from 'get-browser-fingerprint';

const fingerprint = getBrowserFingerprint({
  exclude: ['screen', 'screenHeight', 'screenWidth'] // Exclut les dimensions d'écran
});
const _ = require('lodash');


export async function getCart() {
    const data = {
    'fingerprint' : await fingerprint,
  }

  let response = await fetch(`${process.env.REACT_APP_NGROK_URL}/cart/get`, {
    method: 'POST',      
    headers: {
        "Content-Type": "application/json",
      },
    body: JSON.stringify(data)
  });


  const articles = await response.json();
  return articles;
}

export const addToCart = (id) => {
  return new Promise((resolve, reject) => {
    const data = {
      'fingerprint': fingerprint,
      'item': id
    };

    fetch(`${process.env.REACT_APP_NGROK_URL}/cart/add`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data)
    })
    .then((result) => {
      resolve(result);
    })
    .catch(error => {
      reject(error); 
    });
  });
}

export const removeFromCart = (id) => {
  return new Promise((resolve, reject) => {
    const data = {
      'fingerprint': fingerprint,
      'item': id
    };

    fetch(`${process.env.REACT_APP_NGROK_URL}/cart/remove`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data)
    })
    .then((result) => {
      resolve(result);
    })
    .catch(error => {
      reject(error); 
    });
  });
}

export async function deleteFromCart(id) {
  let items = await getCart()
  _.pull(items, id);

  const data = {
    'fingerprint' : fingerprint,
    'items' : items
  }

  let response = await fetch(`${process.env.REACT_APP_NGROK_URL}/cart`, {
    method: 'POST',      
    headers: {
        "Content-Type": "application/json",
      },
    body: JSON.stringify(data)
  });

  const articles = await response.json();
  return articles;
}

export async function deleteAllFromCart() {
  const data = {
    'fingerprint' : fingerprint,
    'items' : []
  }

  let response = await fetch(`${process.env.REACT_APP_NGROK_URL}/cart`, {
    method: 'POST',      
    headers: {
        "Content-Type": "application/json",
      },
    body: JSON.stringify(data)
  });

  const articles = await response.json();
  return articles;
}

export async function payCart(redirectTo) {
  let items = await getCart();
  const articles = await getStore();

  // Transformation
  items = _.map(items, (id) => {
    const matchingItem = _.find(articles, { _id: id });
    return matchingItem
      ? { id, ...matchingItem }  
      : { id };
    });

   let items_final = _(items)
    .groupBy(process.env.NODE_ENV === 'production'? 'price' : 'price_test')
    .map((elements, key) => ({
        price: key,
        quantity: elements.length 
    }))
    .value();

    console.log(items_final)

  const data = {
    'items' : items_final,
    'redirectTo' : redirectTo
  }

  let response = await fetch(`${process.env.REACT_APP_NGROK_URL}/create-checkout-session`, {
    method: 'POST',      
    headers: {
        "Content-Type": "application/json",
      },
    body: JSON.stringify(data)
  });

  let result = await response.json();
  return result.url
}
