import * as React from 'react';
import { useState, useEffect } from "react";

// CSS
import "./cart.scss";
import { useDrawer } from "./DrawerContext";
import { getCart, addToCart, removeFromCart, deleteFromCart, payCart, deleteAllFromCart} from "./service";
import { getStore} from "../store/service";

import { Form, useLoaderData, useNavigate, useLocation, redirect} from "react-router-dom";

import { styled } from '@mui/material/styles';

import Grid from '@mui/material/Unstable_Grid2';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close'; 

import { 
  TextField, 
  Drawer, 
  IconButton, 
  Avatar, 
  ListItemText, 
  ListItemAvatar, 
  ListItem, 
  List, 
  Typography, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  Box, 
  Card, 
  CardContent, 
  CardMedia, 
  Button, 
  Divider, 
  FormControlLabel, 
  Checkbox } from '@mui/material';


const _ = require('lodash');

const drawerWidth = 500;

const MyDrawer = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
  },
}));

export default function Cart(props){

  const { isDrawerOpen,toggleDrawer, message } = useDrawer();

  const navigate      = useNavigate();

  const { items }     = useLoaderData();

  const countById     = _.countBy(items, "_id");

  const containAlcool = _.some(items, { alcool: true })

  const [isChecked, setIsChecked] = useState(false);

  const location = useLocation();

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const actualURL = encodeURIComponent(location.pathname)

  return (
    <div id="cart" className="Cart">
      { message ? (
        <Message message={message} />
      ) : (
        isDrawerOpen && (
          <MyDrawer variant="persistent" anchor="right" open={isDrawerOpen}>
            <Box sx={{ width: '100%', maxWidth: 600, mx: 'auto', my: 4, p: 2, borderRadius: 2 }}>
              <IconButton
              onClick={() => toggleDrawer()} // Ajoutez ici la méthode pour gérer la fermeture
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
              <Typography variant="h4" sx={{ mb: 3, textAlign: 'center' }}>Panier</Typography>
              {_.isEmpty(countById) ? (
                <Typography variant="h6" color="text.secondary" sx={{ textAlign: 'center' }}>Votre panier est vide.</Typography>
              ) : (
              <>
                {_.entries(countById).map(([id, quantite]) => (
                  <Card key={id} sx={{ display: 'flex', mb: 2 }}>
                    <CardMedia
                      component="img"
                      sx={{ width: 100 }}
                      image={`${_.kebabCase(_.find(items, { _id: id }).titre)}.jpg`}
                      alt={_.find(items, { _id: id }).type}
                      sx={{ width:100 , height: 100, objectFit: "contain", margin: "auto" }}
                    />
                    <CardContent sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                      <Typography variant="h6">{_.find(items, { _id: id }).titre}</Typography>
                      <Typography color="text.secondary">Prix: €{_.find(items, { _id: id }).prix.toFixed(2)}</Typography>
                      
                      <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                        <Form
                          method="post"
                          action={`cart/${id}/remove?redirectTo=${actualURL}`}
                        >
                          <IconButton type="submit" color="primary">
                            <RemoveIcon />
                          </IconButton>
                        </Form>
                        <Typography variant="body1" sx={{ mx: 1 }}>{quantite}</Typography>
                        <Form
                          method="post"
                          action={`cart/${id}/add?redirectTo=${actualURL}`}
                        >
                          <IconButton type="submit" color="primary">
                            <AddIcon />
                          </IconButton>
                        </Form>
                      </Box>
                    </CardContent>
                    <Form
                      method="post"
                      action={`cart/${id}/destroy?redirectTo=${actualURL}`}
                    >
                      <IconButton type="submit" color="primary">
                        <DeleteIcon />
                      </IconButton>
                    </Form>
                  </Card>
                ))}
                <Divider sx={{ my: 2 }} />
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="h6">Total:</Typography>
                  <Typography variant="h6">€{_.sumBy(items, 'prix').toFixed(2)}</Typography>
                </Box>
                {containAlcool ? (
                <FormControlLabel
                  control={
                    <Checkbox 
                      checked={isChecked} 
                      onChange={handleCheckboxChange} 
                      color="primary" 
                    />
                  }
                  label="Je certifie avoir l’âge légal pour consommer de l’alcool"
                />
                ):''}

                <Form
                  method="post"
                  action={`cart/pay?redirectTo=${encodeURIComponent(window.location.pathname)}`}
                >
                  <Button 
                    type="submit" 
                    color="primary" 
                    variant="contained" 
                    fullWidth sx={{ mt: 3 }}
                    disabled={containAlcool && !isChecked}
                  >
                    Passer à la caisse
                  </Button>
                </Form>
              </>
              )}
            </Box>
          </MyDrawer>
        )
      )}
    </div>
  );
}

export async function pay(redirectTo) {
  let url = await payCart(redirectTo);
  window.location.href = url; 
}

export async function addItem(id) {
  let items = await addToCart(id);
  return items
}

export async function removeItem(id) {
  let items = await removeFromCart(id);
  return items
}

export async function deleteItem(id) {
  let items = await deleteFromCart(id);
  return items
}

export async function loader() {
  let items = await getCart();
  const articles = await getStore();

  // Transformation
  items = _.map(items, (id) => {
    const matchingItem = _.find(articles, { _id: id });
    return matchingItem
      ? { id, ...matchingItem }  
      : { id };
    });

  return { items };
}

function Prevention({ message}){
  const navigate = useNavigate();
  const location = useLocation();
  const { setMessage } = useDrawer();

  const [open, setOpen] = useState(true);

  // Fermer la modale
  const handleClose = () => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has("success")) {
      deleteAllFromCart();
    }
    setMessage('')
    setOpen(false);
    navigate(location.pathname, { replace: true });
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {_.split(message, /(?<=[.!?])\s+/)[0]}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            {_.split(message, /(?<=[.!?])\s+/)[1]}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

//const Message = ({ message }) => (
function Message({ message}){
  const navigate = useNavigate();
  const location = useLocation();
  const { setMessage } = useDrawer();

  const [open, setOpen] = useState(true);

  // Fermer la modale
  const handleClose = () => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has("success")) {
      deleteAllFromCart();
    }
    setMessage('')
    setOpen(false);
    navigate(location.pathname, { replace: true });
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {_.split(message, /(?<=[.!?])\s+/)[0]}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            {_.split(message, /(?<=[.!?])\s+/)[1]}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
