import * as React from 'react';
import { useEffect,useState } from 'react';
import { useLocation } from "react-router-dom";

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery'
import { getItem} from "./service";

import '@fontsource/roboto/300.css';
import './item.scss';

import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';

const _ = require('lodash');

export default function Item(){
	const location = useLocation();
	const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { item } = location.state || {};

  return (
  	<div id="item" className="Item" style={{ width: '100%', margin: '16px', marginRight: '50px'}}>
  		<Grid xs={12} container spacing={2} padding={2} direction={isSmallScreen ? "column" : "row"} 
  				sx={{bgcolor: 'white', p: 2, borderRadius: 5, margin: '16px'}}>
					<Grid xs={3} textAlign="justify">
						<Box
	            component="img"
	            src={`${_.kebabCase(item.titre)}.jpg`}
	            alt={item.titre}
					    sx={{
					      width: '50%', // L'image occupe toute la largeur du conteneur
					      height: 'auto', // La hauteur s'ajuste automatiquement pour conserver les proportions
					      objectFit: 'cover', // Assure que l'image couvre tout l'espace sans être déformée
					      transition: 'all 0.3s ease', // Animation fluide pour les changements
					    }}
	          />
	  			</Grid>

	  			<Grid xs={9} textAlign="justify" container direction={isSmallScreen ? "column" : "row"} >
	  				<Typography variant="h6" sx={{ m: '0.5rem', fontWeight: 'bold', textAlign: 'center'}}>
              {item.titre.toUpperCase()} <br />
            </Typography>
            <Typography variant="h6" sx={{ m: '0.5rem', textAlign: 'center'}}>
              {item.description} 
            </Typography>

		  			<Grid xs={isSmallScreen ? 12 : 6} textAlign="justify" sx={{padding: '8px'}}>
		  				
		  			</Grid>
		  			<Grid xs={isSmallScreen ? 12 : 6} textAlign="justify" sx={{padding: '8px'}}>
		  				
		  			</Grid>
		  		</Grid>
  		</Grid>
    </div>
  );
}


export async function loader() {
  const item = await getItem();
  return { item };
}

export async function action({ request, params }) {
  /*const formData = await request.formData();
  let id = formData.get("id");
  const cart = await addToCart(id);
  return { cart };*/
}