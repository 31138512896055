import '@fontsource/roboto/300.css';

import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {ReactComponent as LogoIcon} from './logo.svg';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery'
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import Badge from '@mui/material/Badge';
import { Outlet, Link } from "react-router-dom";
import Login from "../login/login"
import './home.scss';
import { getCart } from "../cart/service";
import { useEffect } from "react";
import { useLoaderData } from "react-router-dom";
import { useTheme } from '@mui/material/styles';

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import { useDrawer } from "../cart/DrawerContext";

const drawerWidth = 500;

const MyAppBar = styled(AppBar, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: `calc(100% - ${open ? drawerWidth : 0}px)`, // Ajustement de la largeur
    ...(open && {
      marginRight: drawerWidth,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  })
);

function Logo() {
  return (
    <div  >
      <Link to={'/'} className="center Link">
      <IconButton title='logo' >
          <LogoIcon className='logo animation' />
      </IconButton>
      <Titre />
      </Link>
    </div>
  );
}

function Titre() {
  return (
    <Typography className='titre' color="secondary">
        La Pomme Briarde 
    </Typography>
  );
}

function Store() {
  return (
    <Button >
        <Link to={'store'} className="Link">
          <Typography color="secondary">
            magasin
          </Typography>
        </Link>
    </Button>
  );
}

function About() {
  return (
    <Button >
        <Link to={'about'} className="Link">
          <Typography color="secondary">
            à propos
          </Typography>
        </Link>
    </Button>
  );
}

function Contact() {
  return (
    <Button >
        <Link to={'contact'} className="Link">          
          <Typography className='titre' color="secondary">
            contact
          </Typography>
        </Link>
    </Button>
  );
}

function LogoEtat(){
  return (
      <Box >
          <img src="logo-etat.jpg" alt="Logo Etat" style={{ maxWidth: '50px', overflow: 'hidden' }} />
      </Box>
  );
}

function MessagePrevention(){
  return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
          <LogoEtat/>

          <Typography variant="body2" color="text.secondary" style={{ marginLeft: '10px' }}>
            L'abus d'alcool est dangereux pour la santé, à consommer avec modération.
          </Typography>
      </div>
  );
}

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" paddingLeft={5}>
      {'Copyright © '}
      <Link  href="http://lapommebriarde.fr/">
        lapommebriarde.fr
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function Cart() {
  const { items } = useLoaderData();
  const { toggleDrawer } = useDrawer();

  return (
    <div>
      <Box sx={{ flexGrow: 0 }}>
        <Tooltip>
          <Badge badgeContent={items ? items.length : 0} color="secondary">
            <IconButton onClick={toggleDrawer}>
              <ShoppingCartIcon className='cart' color="primary"/>
            </IconButton>
          </Badge>
        </Tooltip>
      </Box>
    </div>
  );
}

function NavigationBar() {
  const { isDrawerOpen } = useDrawer();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <MyAppBar position="sticky" open={isDrawerOpen} color='background'>
      <Toolbar variant="dense" sx={{ display: 'flex', alignItems: 'center' }}>
      < Logo />

      <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
        <Grid
          container
          justifyContent="center" // Assurez-vous que le contenu est centré
          alignItems="center"
          spacing={2}
          direction={isSmallScreen ? "column" : "row"} // Colonne sur petit écran, ligne sur grand écran
        >
          <Grid >
            < Store />
          </Grid>

          <Grid >
            < About />
          </Grid>

          <Grid >
            {/*< Contact />*/}
          </Grid>

          <Grid >
            {/*< Login />*/}
          </Grid>
        </Grid>
        </Box>

        < Cart />
      </Toolbar>
    </MyAppBar>
  );
}

function Footer() {
  const { isDrawerOpen } = useDrawer();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <MyAppBar position="sticky" open={isDrawerOpen} color='background' sx={{ py: 3, mt: 'auto', bgcolor: 'white'}} >
      <Box component="footer" position="sticky" >
        <Box>
          <Grid
            container
            spacing={2}
            direction={isSmallScreen ? "column" : "row"} // Colonne sur petit écran, ligne sur grand écran
          >
            <Grid  xs={4} sm={4} paddingLeft={5}>
              < Copyright />
            </Grid>

            <Grid  xs={6} >
              < MessagePrevention />
            </Grid>

          </Grid>
        </Box>
      </Box>
    </MyAppBar>
  );
}


export default function Home(){
  return (
    <div id="home" className="Home">
      <Box sx={{flexGrow: 1 , display: 'flex',flexDirection: 'column', minHeight: '100vh'}}>
        < NavigationBar/>
        <Grid container id="main">
          <Outlet/>
        </Grid>
        < Footer />
      </Box>
    </div>
  );
}

export async function loader() {
  const items = await getCart();
  return { items };
}

