// LIBRARIES
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createRoutesFromElements,
  createBrowserRouter,
  Route,
  RouterProvider
} from "react-router-dom";

// CSS
import "./index.css";

// PAGES
import Error from "./routes/error/error";
import App from "./routes/app/app";
import Cart from "./routes/cart/cart";
import Contact from "./routes/contact/contact";
import Store from "./routes/store/store"
import About from "./routes/about/about"
import Item from "./routes/item/item"

// Theme
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./theme";

import { 
  loader as storeLoader,
  action as storeAction, 
} from  "./routes/store/store";

import { 
  loader as itemLoader,
  action as itemAction, 
} from  "./routes/item/item";

import { action as destroyAction } from "./routes/cart/destroy";
import { action as addAction } from "./routes/cart/add";
import { action as removeAction } from "./routes/cart/remove";
import { action as payAction } from "./routes/cart/pay";

import { loader as homeLoader} from  "./routes/home/home";
import { loader as cartLoader} from  "./routes/cart/cart";

import { DrawerProvider } from "./routes/cart/DrawerContext";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={<App />}
      loader={homeLoader, cartLoader}
      action={[addAction, removeAction, destroyAction, payAction]}
      errorElement={<Error />}
    >          
      <Route errorElement={<Error />}>
        <Route
          path="contact"
          element={<Contact />}
        />
      </Route>

      <Route errorElement={<Error />}>
        <Route
          path="about"
          element={<About />}
        />
      </Route>

      <Route errorElement={<Error />}>
        <Route
          path=":type"
          element={<Item />}
          loader={itemLoader}
          action={itemAction}
        />
      </Route>

      <Route errorElement={<Error />}>
        <Route
          path="store"
          element={<Store/>}
          loader={storeLoader}
          action={storeAction}
        />
      </Route>

      <Route errorElement={<Error />}>
        <Route 
          path="cart" 
          element={<Cart />} 
          loader={cartLoader} 
          errorElement={<Error />}
        >
          <Route 
            path=":itemId/add" 
            action={addAction} 
            errorElement={<Error />} 
          />
          <Route 
            path=":itemId/remove" 
            action={removeAction} 
            errorElement={<Error />} 
          />
          <Route 
            path=":itemId/destroy" 
            action={destroyAction} 
            errorElement={<Error />} 
          />
          <Route 
            path="pay" 
            action={payAction} 
            errorElement={<Error />} 
          />
        </Route>
      </Route>
    </Route>
  )
);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
   <ThemeProvider theme={theme}>
      <CssBaseline /> {/* Applique le style de réinitialisation globale */}
      <DrawerProvider>
        <RouterProvider router={router} />
      </DrawerProvider>
    </ThemeProvider>
  </React.StrictMode>
);
